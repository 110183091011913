// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Js_exn from "../../../../node_modules/rescript/lib/es6/js_exn.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json__Decode from "../../../../node_modules/rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Generic$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/utils/Generic.bs.js";
import * as URLUtils$Showside from "./URLUtils.bs.js";
import * as FetchUtils$Showside from "./FetchUtils.bs.js";
import * as Settings_GaVersionCodecs$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/Settings/Settings_GaVersionCodecs.bs.js";

function initialDocumentCookie(param) {
  return Belt_Option.flatMap(Belt_Option.map(Belt_Option.flatMap(Belt_Option.map(Belt_Option.flatMap(Belt_Option.map((window.ShopifyAnalytics == null) ? undefined : Caml_option.some(window.ShopifyAnalytics), (function (prim) {
                                    return prim.lib;
                                  })), (function (prim) {
                                if (prim == null) {
                                  return ;
                                } else {
                                  return Caml_option.some(prim);
                                }
                              })), (function (prim) {
                            return prim.config;
                          })), (function (prim) {
                        if (prim == null) {
                          return ;
                        } else {
                          return Caml_option.some(prim);
                        }
                      })), (function (prim) {
                    return prim.initialDocumentCookie;
                  })), (function (prim) {
                if (prim == null) {
                  return ;
                } else {
                  return Caml_option.some(prim);
                }
              }));
}

function isShopify(param) {
  return Belt_Option.isSome(window.Shopify);
}

function getShop(param) {
  return Belt_Option.flatMap(window.Shopify, (function (shopify) {
                return shopify.shop;
              }));
}

function getCountry(param) {
  return Belt_Option.flatMap(window.Shopify, (function (shopify) {
                return shopify.country;
              }));
}

function getLanguage(param) {
  return Belt_Option.flatMap(window.Shopify, (function (shopify) {
                return shopify.locale;
              }));
}

function removeForcedSizeFromURL(__x) {
  return __x.replace(/(_\d+x(\d+|)).(\w{3,4}\?)/, ".$3");
}

function makeUrl(url, w, h, dprOpt, unit) {
  var dpr = dprOpt !== undefined ? dprOpt : 1;
  var url$1 = removeForcedSizeFromURL(url);
  var width = Belt_Option.mapWithDefault(w, "", (function (w) {
          return "&width=" + String(Math.imul(w, dpr));
        }));
  var height = Belt_Option.mapWithDefault(h, "", (function (h) {
          return "&height=" + String(Math.imul(h, dpr));
        }));
  return url$1 + width + height;
}

function makeCoverUrl(url, w, h, naturalWidth, naturalHeight, dprOpt, unit) {
  var dpr = dprOpt !== undefined ? dprOpt : 1;
  var url$1 = removeForcedSizeFromURL(url);
  var param;
  if (w !== undefined && h !== undefined) {
    if (naturalWidth !== undefined) {
      if (naturalHeight !== undefined) {
        var dw = w / naturalWidth;
        var dh = h / naturalHeight;
        param = dh < dw ? "&width=" + String(Math.imul(w, dpr)) + "" : "&height=" + String(Math.imul(h, dpr)) + "";
      } else {
        param = "";
      }
    } else {
      param = naturalHeight !== undefined ? "" : (
          h < w ? "&width=" + String(Math.imul(w, dpr)) + "" : "&height=" + String(Math.imul(h, dpr)) + ""
        );
    }
  } else {
    param = "";
  }
  return url$1 + param;
}

function isShopifyImg(src) {
  return src.includes("cdn.shopify.com");
}

var t_decode = Curry._4(Generic$ApiTypes.Decode.map3, Curry._2(Generic$ApiTypes.Decode.field, "gaTrackingId", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Generic$ApiTypes.Decode.fieldWithDefault("gaVersion", Settings_GaVersionCodecs$ApiTypes.GaVersion.fromJson, /* Gtag */1), Curry._2(Generic$ApiTypes.Decode.field, "checkoutLink", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), (function (gaTrackingId, gaVersion, checkoutLink) {
        return {
                gaTrackingId: gaTrackingId,
                gaVersion: gaVersion,
                checkoutLink: checkoutLink
              };
      }));

var Settings = {
  t_decode: t_decode
};

var t_decode$1 = Json__Decode.map3(Json__Decode.field("id", Json__Decode.string), Json__Decode.field("accountId", Json__Decode.option(Json__Decode.string)), Json__Decode.field("settings", t_decode), (function (id, accountId, settings) {
        return {
                id: id,
                accountId: accountId,
                settings: settings
              };
      }));

function load(smartnavApiHost) {
  var shop = Belt_Option.flatMap(window.Shopify, (function (shopify) {
          return shopify.shop;
        }));
  if (shop === undefined) {
    return Promise.reject(Js_exn.raiseError("Shopify store not found"));
  }
  if (shop === "") {
    return Promise.reject(Js_exn.raiseError("Shopify store not found"));
  }
  var url = smartnavApiHost + "/api/get_shopify_url_config?url=" + encodeURIComponent("https://" + shop);
  return FetchUtils$Showside.$$fetch(undefined, undefined, undefined, undefined, (function (json) {
                return Json__Decode.decodeValue(json, t_decode$1);
              }), url);
}

var Config = {
  Settings: Settings,
  t_decode: t_decode$1,
  fromJson: t_decode$1,
  load: load
};

function isProductPage(param) {
  var canonical = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(document.querySelector("link[rel='canonical']")), (function (el) {
              return Caml_option.nullable_to_opt(el.getAttribute("href"));
            })), "");
  var pathname = window.location.pathname;
  if (pathname.includes("/products/") || canonical.includes("/products/") || pathname.includes("/product/")) {
    return true;
  } else {
    return canonical.includes("/product/");
  }
}

var idToString = (function idToString(id) {
    return String(id);
  });

function getProductPageProductId(param) {
  if (isProductPage(undefined)) {
    return Belt_Option.map(Belt_Option.flatMap(Belt_Option.map(Belt_Option.flatMap(Belt_Option.map(Belt_Option.flatMap(Belt_Option.map((window.ShopifyAnalytics == null) ? undefined : Caml_option.some(window.ShopifyAnalytics), (function (prim) {
                                          return prim.meta;
                                        })), (function (prim) {
                                      if (prim == null) {
                                        return ;
                                      } else {
                                        return Caml_option.some(prim);
                                      }
                                    })), (function (prim) {
                                  return prim.product;
                                })), (function (prim) {
                              if (prim == null) {
                                return ;
                              } else {
                                return Caml_option.some(prim);
                              }
                            })), (function (prim) {
                          return prim.id;
                        })), (function (prim) {
                      if (prim == null) {
                        return ;
                      } else {
                        return Caml_option.some(prim);
                      }
                    })), idToString);
  }
  
}

function getPage(param) {
  return Belt_Option.flatMap(Belt_Option.map(Belt_Option.flatMap(Belt_Option.map((window.ShopifyAnalytics == null) ? undefined : Caml_option.some(window.ShopifyAnalytics), (function (prim) {
                            return prim.meta;
                          })), (function (prim) {
                        if (prim == null) {
                          return ;
                        } else {
                          return Caml_option.some(prim);
                        }
                      })), (function (prim) {
                    return prim.page;
                  })), (function (prim) {
                if (prim == null) {
                  return ;
                } else {
                  return Caml_option.some(prim);
                }
              }));
}

function getCheckoutLink(cart, clientId, transitiveStore, checkoutLink) {
  var getVariantId = function (variantId, url) {
    var isNumeric = variantId.length === Belt_Option.getWithDefault(Belt_Option.getWithDefault(Belt_Array.get(Belt_Option.getWithDefault(Caml_option.null_to_opt(variantId.match(/([0-9]+)/)), []), 0), undefined), "").length;
    var variantIdFromLink = Belt_Array.get(Belt_Option.getWithDefault(Caml_option.null_to_opt(Belt_Option.getWithDefault(url, "").match(/variant=([0-9]+)/)), []), 1);
    if (isNumeric) {
      return variantId;
    }
    if (variantIdFromLink === undefined) {
      return variantId;
    }
    var variantIdFromLink$1 = Caml_option.valFromOption(variantIdFromLink);
    if (variantIdFromLink$1 !== undefined) {
      return variantIdFromLink$1;
    } else {
      return variantId;
    }
  };
  var baseUrl = Belt_Option.map(Belt_Option.flatMap(checkoutLink, URLUtils$Showside.maybeMakeUrl), (function (prim) {
          return prim.origin;
        }));
  return Belt_Option.map(Belt_Option.flatMap(baseUrl, URLUtils$Showside.maybeMakeUrl), (function (baseUrl) {
                var queryParams = [];
                var token = Belt_Option.flatMap(transitiveStore, (function (store) {
                        return store.shopifyStorefrontAccessToken;
                      }));
                var queryParams$1 = token !== undefined ? Belt_Array.concat(queryParams, ["access_token=" + token + ""]) : queryParams;
                var queryParams$2 = clientId !== undefined ? Belt_Array.concat(queryParams$1, ["ref=mkr|" + clientId[0] + "|" + clientId[1] + ""]) : queryParams$1;
                var queryParams$3 = Belt_Array.joinWith(queryParams$2, "&", (function (a) {
                        return a;
                      }));
                var queryParams$4 = queryParams$3 === "" ? "" : "?" + queryParams$3;
                return baseUrl.origin + "/cart/" + Belt_Array.joinWith(Belt_Option.mapWithDefault(cart, [], (function (cart) {
                                  return cart.items;
                                })), ",", (function (param) {
                              return getVariantId(param.variantId, param.url) + ":" + String(param.quantity);
                            })) + queryParams$4;
              }));
}

export {
  initialDocumentCookie ,
  isShopify ,
  getShop ,
  getCountry ,
  getLanguage ,
  removeForcedSizeFromURL ,
  makeUrl ,
  makeCoverUrl ,
  isShopifyImg ,
  Config ,
  isProductPage ,
  idToString ,
  getProductPageProductId ,
  getPage ,
  getCheckoutLink ,
}
/* t_decode Not a pure module */
