// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Rollbar from "../../../../node_modules/rescript-rollbar/lib/es6_global/src/Rollbar.bs.js";
import * as Caml_exceptions from "../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as Caml_js_exceptions from "../../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";

var importRollbarClient = (function () {
  return import('rollbar/dist/rollbar.noconflict.umd')
  .then(rollbar => {
    return (options) => {
      return new rollbar(options)
    }
  })
});

async function getInstance(param) {
  var makeClient = await Curry._1(importRollbarClient, undefined);
  return makeClient({
              accessToken: "4828ec1b7d004715a4570b8384684fc2",
              captureUncaught: false,
              captureUnhandledRejections: false,
              environment: process.env.NODE_ENV,
              checkIgnore: (function (param) {
                  var hostname = window.location.hostname;
                  if (hostname.endsWith("makerco.vercel.app") || hostname.endsWith("nav.maker.co") || hostname.endsWith(".myshopify.com")) {
                    return true;
                  } else {
                    return hostname.endsWith(".glitch.me");
                  }
                }),
              payload: {
                client: {
                  javascript: {
                    source_map_enabled: true,
                    code_version: process.env.VERCEL_GIT_COMMIT_SHA,
                    guess_uncaught_frames: true
                  }
                }
              }
            });
}

var LogicalError = /* @__PURE__ */Caml_exceptions.create("RollbarInstance-Showside.LogicalError");

var LogicalErrorUnreported = /* @__PURE__ */Caml_exceptions.create("RollbarInstance-Showside.LogicalErrorUnreported");

function makeError(reportOpt, message) {
  var report = reportOpt !== undefined ? reportOpt : true;
  try {
    throw report ? ({
              RE_EXN_ID: LogicalError,
              _1: message
            }) : ({
              RE_EXN_ID: LogicalErrorUnreported,
              _1: message
            });
  }
  catch (raw_err){
    return Caml_js_exceptions.internalToOCamlException(raw_err);
  }
}

function jsError1(error) {
  return getInstance(undefined).then(function (__x) {
              return Rollbar.error1(__x, error);
            });
}

function jsError2(error, extraInfo) {
  return getInstance(undefined).then(function (__x) {
              return Rollbar.error2(__x, error, extraInfo);
            });
}

function jsError3(error, extraInfo1, extraInfo2) {
  return getInstance(undefined).then(function (__x) {
              return Rollbar.error3(__x, error, extraInfo1, extraInfo2);
            });
}

function error1(error) {
  return jsError1(Rollbar.$$Error.fromRescript(error));
}

function error2(error, extraInfo) {
  return jsError2(Rollbar.$$Error.fromRescript(error), extraInfo);
}

function error3(error, extraInfo1, extraInfo2) {
  return jsError3(Rollbar.$$Error.fromRescript(error), extraInfo1, extraInfo2);
}

function info1(msg) {
  return getInstance(undefined).then(function (__x) {
              return Rollbar.info1(__x, msg);
            });
}

function info2(msg, extraInfo) {
  return getInstance(undefined).then(function (__x) {
              return Rollbar.info2(__x, msg, extraInfo);
            });
}

function warning1(msg) {
  return getInstance(undefined).then(function (__x) {
              return Rollbar.warning1(__x, msg);
            });
}

function warning2(msg, extraInfo) {
  return getInstance(undefined).then(function (__x) {
              return Rollbar.warning2(__x, msg, extraInfo);
            });
}

export {
  importRollbarClient ,
  getInstance ,
  LogicalError ,
  LogicalErrorUnreported ,
  makeError ,
  jsError1 ,
  jsError2 ,
  jsError3 ,
  error1 ,
  error2 ,
  error3 ,
  info1 ,
  info2 ,
  warning1 ,
  warning2 ,
}
/* No side effect */
